body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  line-height: 32px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

.App {
  width: 720px;
  margin: 0 auto;
}

.header {
  border-radius: 0px 0px 8px 8px;
  width: 720px;
  height: 80px;
  margin-bottom: 40px;
  position: relative;
  background-image: url('Background.png');
}

.header a {
  position: absolute;
  top: 30px;
  left: 40px;
}

.footer {
  background: linear-gradient(208.23deg, #874fff 8.5%, #1c32f3 91.95%);
  border-radius: 8px 8px 0px 0px;
  width: 720px;
  height: 80px;
  position: relative;
}

.footer a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
}

.MuiPaper-root {
  color: #000b64 !important;
}

.MuiFormLabel-colorPrimary {
  color: #303030 !important;
}

.muiPaper {
  padding: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  color: #000b64;
  border-radius: 8px;
}

.paperBg {
  box-shadow: 0px 4px 12px rgba(11, 63, 246, 0.4);
  border-radius: 8px;
  color: black;
}

.bookieLink {
  font-weight: 900;
  text-decoration: none;
  color: #0135ee;
  font-size: 20px;
}

.bookieLink a {
  font-weight: 900;
  text-decoration: none;
  color: #0135ee;
  font-size: 20px;
}

.formTitle {
  font-size: 26px;
  font-weight: 900;
  margin-top: 0;
  color: #000b64;
}

.stepsTitle {
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 48px;
  color: #000b64;
}

.caption {
  font-size: 20px;
  font-weight: 800;
  color: #000b64;
}

.formLabel {
  margin-bottom: 12px;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 28px !important;
}

.formControlClass {
  display: flex;
  flex-direction: column;
  color: black;
}

.blueText {
  color: #1a00bd;
  font-weight: 400;
}

.spanSlim {
  font-weight: 400;
  color: #303030;
}

.slimText {
  font-weight: 400;
  margin-top: 26px;
  color: #303030;
}

.marginTop48 {
  margin-top: 48px;
}

.marginBottom28 {
  margin-top: 28px;
}

.marginTop28 {
  margin-top: 28px;
}

.textInput {
  width: 227px;
}

.primaryBtnDefault {
  padding: 12px 32px;
  gap: 10px;
  background: #fdb400;
  box-shadow: 0px 4px 8px rgba(253, 180, 0, 0.25);
  border: none;
  border-radius: 8px;
  font-size: 20px;
  text-decoration: none;
}

.primaryBtnDefault a {
  text-decoration: none;
  background: #fdb400;
  box-shadow: 0px 4px 8px rgba(253, 180, 0, 0.25);
  border: none;
  border-radius: 8px;
}

.primaryBtnDefault:hover {
  background: #fdd500;
  box-shadow: 0px 4px 8px rgba(253, 180, 0, 0.25);
}

.primaryBtnDefault:active {
  background: #fdb400;
  box-shadow: 0px 4px 8px rgba(253, 180, 0, 0.25);
}

.secondaryBtnDefault {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000b64;
  border: none;
  background-color: transparent;
  padding: 12px 32px;
  gap: 10px;
  margin-bottom: 28px;
}

.secondaryBtnDefault:hover {
  color: #fdb400;
}

.linkedBtnDefault {
  padding: 12px 32px;
  gap: 10px;
  background: transparent;
  box-shadow: 0px 4px 8px rgba(253, 180, 0, 0.25);
  border: 1px solid #fdb400;
  border-radius: 8px;
  font-size: 20px;
  text-decoration: none;
}

.linkedBtnDefault:hover {
  background: #fdd500;
  color: white;
  box-shadow: 0px 4px 8px rgba(253, 180, 0, 0.25);
}

.thirdBtn {
  padding: 12px 32px;
  gap: 10px;
  background: transparent;
  box-shadow: 0px 4px 8px #fdb400 25%;
  border: 1px solid #fdb400;
  border-radius: 8px;
  font-size: 20px;
}

.thirdBtn:hover {
  background-color: #fdd500;
  color: white;
}

.disabledBtn {
  background: #cccccc;
  color: #7c7c7c;
  border: 1px solid #7c7c7c;
  box-shadow: 0px 4px 8px #cccccc 25%;
  padding: 12px 32px;
  gap: 10px;
  border-radius: 8px;
  font-size: 20px;
  text-decoration: none;
}

.resRow {
  display: inline-block;
  font-size: 20px;
  margin-bottom: 28px;
  width: 640px;
  background: transparent;
}

.resultOuter {
  width: 640px;
  position: relative;
}

.resultValue {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 32px;
  border-radius: 4px;
  background-color: transparent;
}

.resultNr {
  margin-left: 0.8em;
}

.place0 {
  background-color: #99ff80;
}
.place1 {
  background-color: #f5ff80;
}
.place2 {
  background-color: #ffdb80;
}
.place3 {
  background-color: #ff8080;
}

.btnContainer {
  position: relative;
}

.contFlex {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
}

.toon {
  width: 153px;
  position: absolute;
  bottom: 28px;
  right: 40px;
}

.infoImg {
  padding-left: 1rem;
}

.resPlace {
  font-weight: 750;
  color: #1a00bd;
}

.MuiTooltip-tooltip {
  line-height: 1.2em;
}

.disclaimer {
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .stepsTitle {
    margin-bottom: 28px;
  }

  body {
    font-size: 24px;
  }
  .muiPaper {
    padding: 16px;
    gap: 10px;
    margin: 24px;
  }
  .primaryBtnDefault {
    padding: 16px 36px;
    font-size: 26px;
  }
  .linkedBtnDefault {
    padding: 16px 16px;
    font-size: 26px;
  }
  .resultOuter {
    width: 600px;
    position: relative;
  }
}

div.MuiPaper-root {
  background-color: transparent;
}
